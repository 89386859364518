import { versions } from './versions';

const brand = window.brand;

function getTitle(v) {
	if (typeof v === 'string') {
		return v;
	}
	return v.title;
}

function getVersion(v) {
	if (typeof v === 'string') {
		return v;
	}
	return v.version;
}

function getVersionDetails(productId, version) {
	const brandVersion = version[brand];
	const _versionTitle = getTitle(brandVersion);
	const _version = getVersion(brandVersion);

	const _url = brandVersion.url;

	let href;

	if (_url) {
		href = _url;
	} else {
		const urlProduct = version.productOverride
			? versions.products[version.productOverride].product[brand]
			: versions.products[productId].product[brand];
		const url = versions
			.urlTemplates[brand]
			.replace(
				'{product}',
				urlProduct
			);
		href = `${ url.replace('{version}', _version.replace(/\./g, '_')) }${ version.topic ? version.topic : 'index.htm' }`;
	}

	return {
		versionTitle: _versionTitle,
		href
	};
}

function renderProduct(id) {
	const productData = versions.products[id];
	const container = document.getElementById(`${ id }-tile`);

	const firstVersion = productData.versions
		.filter(v => v[brand])[0];

	if (firstVersion == null) {
		// remove tile
		container.parentNode.removeChild(container);
		return;
	}

	const firstDetails = getVersionDetails(id, firstVersion);

	if (container) {

		const prodTitle = productData.title[brand];
		let prodLink = `&product=${ encodeURIComponent(productData.title[brand]) }`;

		if (prodTitle === 'Solutions') {
			prodLink = '';
		}

		const html = `
            <h3>
				${ prodTitle }
				<a class="history" href="/product-info/?area=version-history${ prodLink }">Version history</a>
			</h3>
            <div>
            	<a href="${ firstDetails.href }">
                	<img src="media/${ productData.thumbnail }" />
                </a>
                <ul class="se-content">${
	productData.versions
		.filter(v => v[brand])
		.map((version) => {

			const details = getVersionDetails(id, version);

			return (
				`
					<li>
						<a href="${ details.href }">
							<i>
								<div class="icomoon-graduation-cap-20"></div>
							</i>
							<span>${ details.versionTitle }</span>
						</a>
					</li>
				`
			);
		})
		.join('')
}
                </ul>
            </div>
        `;

		container.innerHTML = html;
	}
}

function renderTitle() {
	document.getElementById('title').innerHTML = versions.title[brand];
	document.title = versions.title[brand];
}

document.body.classList.add(brand);

renderTitle();
renderProduct('spw');
renderProduct('lite');
renderProduct('xy');
renderProduct('sw');
renderProduct('apps');
