export const versions = {
	title: {
		ge: 'GeoSpatial Analysis',
		se: 'Spatial Eye Products'
	},

	urlTemplates: {
		ge: 'https://documentation.geospatialanalysis.online/{product}/{version}/en/',
		se: 'https://documentation.spatial-eye.com/{product}/{version}/en/'
	},

	products: {
		spw: {
			title: {
				ge: 'GeoSpatial Analysis',
				se: 'Spatial Workshop'
			},
			product: {
				ge: 'gsa',
				se: 'spw'
			},
			thumbnail: 'spw1.png',
			versions: [
				{ ge: '5.3.4', se: '2024.1' },
				{ ge: '5.3.3', se: '2023.2' },
				{ ge: '5.3.2', se: '2023.1' },
				{ ge: '5.3.1', se: '2022.4' },
				{ ge: '5.3.0.4', se: '2022.3' },
				{ ge: '5.3.0', se: '2022.2' },
				{ ge: '5.2.11', se: '2022.1' },
				{ ge: '5.2.10', se: '2021.4' },
				{ ge: '5.2.9', se: '2021.3' },
				{ ge: '5.2.8', se: '2021.2' }
			]
		},

		lite: {
			title: {
				ge: 'GeoSpatial Analysis Lite',
				se: 'Spatial Workshop Lite'
			},
			product: {
				ge: 'gsalite',
				se: 'spwlite'
			},
			thumbnail: 'lite1.png',
			versions: [
				{ ge: '5.3.4', se: '2024.1' },
				{ ge: '5.3.3', se: '2023.2' },
				{ ge: '5.3.2', se: '2023.1' },
				{ ge: '5.3.1', se: '2022.4' },
				{ ge: '5.3.0.4', se: '2022.3' },
				{ ge: '5.3.0', se: '2022.2' },
				{ ge: '5.2.11',	se: '2022.1' },
				{ ge: '5.2.10', se: '2021.4' },
				{ ge: '5.2.9', se: '2021.3' },
				{ ge: '5.2.8', se: '2021.2' }
			]
		},

		xy: {
			title: {
				ge: 'GeoSpatial Analysis Server',
				se: 'X&Y Server'
			},
			product: {
				ge: 'gsaserver',
				se: 'xy'
			},
			thumbnail: 'portal1.png',
			versions: [
				{ ge: '5.3.4', se: '2024.1' },
				{ ge: '5.3.3', se: '2023.2' },
				{ ge: '5.3.2', se: '2023.1' },
				{ ge: '5.3.1', se: '2022.4' },
				{ ge: '5.3.0.4', se: '2022.3' },
				{ ge: '5.3.0', se: '2022.2' },
				{ ge: '5.2.11', se: '2022.1' },
				{ ge: '5.2.10', se: '2021.4' },
				{ ge: '5.2.9', se: '2021.3' },
				{ ge: '5.2.8', se: '2021.2' }
			]
		},

		sw: {
			title: {
				ge: 'GeoSpatial Analysis Warehouse',
				se: 'Spatial Warehouse'
			},
			product: {
				ge: 'gsawarehouse',
				se: 'swh'
			},
			thumbnail: 'portal1.png',
			versions: [
				{ ge: '5.3.4', se: '2024.1' },
				{ ge: '5.3.3', se: '2023.2' },
				{ ge: '5.3.2', se: '2023.1' },
				{ ge: '5.3.1', se: '2022.4' },
				{ ge: '5.3.0.4', se: '2022.3' },
				{ ge: '5.3.0', se: '2022.2' },
				{ ge: '5.2.11', se: '2022.1' },
				{ ge: '5.2.10', se: '2021.4' },
				{ ge: '5.2.9', se: '2021.3' },
				{ ge: '5.2.8', se: '2021.2' }
			]
		},

		apps: {
			title: {
				ge: 'Solutions',
				se: 'Solutions'
			},
			product: {},
			thumbnail: 'apps.png',
			versions: [
				{
					ge: null,
					se: {
						title: 'OneCall KLIC-WIN',
						url: 'https://documentation.spatial-eye.com/ocklicwin/2024_1/nl/index.htm',
						version: '2024.1'
					}
				},
				{
					ge: null,
					se: {
						title: 'OneCall KLIP-KLIM',
						url: 'https://documentation.spatial-eye.com/ocklipklim/2024_1/nl/index.htm',
						version: '2024.1'
					}
				},
				{
					ge: {
						title: 'GSA SSM',
						url: 'https://documentation.geospatialanalysis.online/gsassm/5_3_4/en/index.htm',
						version: '5.3.4'
					},
					se: null
				}
			]
		}
	}
};
